import './start'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Defaults } from 'recore/constants'
import {
  AlertManager,
  AppContext,
  NotificationManager,
  useAppContextMediaQuery,
  useAppContextOverlayCounter,
  useUtilitiesContext,
  UtilitiesContext,
} from 'recore/contexts'
import { AlertUtilities } from 'recore/modules/utilities/alert'
import { NotificationUtilities } from 'recore/modules/utilities/notification'
import { Lang } from 'recore/types'

import { useFirebaseRCValue } from './libs'
import Router from './router'
import MaintenanceRouter from './router-maintenance'

Sentry.init({
  environment: process.env.REACT_APP_NODE_ENV,
  release: `${process.env.REACT_APP_NODE_ENV}@${
    process.env.REACT_APP_SENTRY_RELEASE || ''
  }`,
  integrations: [new BrowserTracing()],
  beforeSend(event, hint) {
    const originalException = hint.originalException
    if (
      typeof originalException === 'string' &&
      originalException.indexOf('this error can be safely ignored') >= 0
    ) {
      return null
    }
    return event
  },
  dsn: '', // set empty to disable sentry
  // process.env.REACT_APP_SENTRY_DSN ||
  // 'https://519f6574ca884c2b889458f9600ea503@o1279780.ingest.sentry.io/6739649',
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1.0,
  sampleRate: 0.05,
  tracesSampler: () => {
    return false
  },

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})

function App() {
  const maintenanceMode = useFirebaseRCValue('TOGGLE_MENTOR_MAINTENANCE_MODE')

  const [lang, setLang] = useState(Defaults.LANG)

  const app = {
    get lang() {
      return lang
    },
    set lang(l: Lang) {
      setLang(l)
    },
    media: useAppContextMediaQuery('desktop'),
    overlay: useAppContextOverlayCounter(),
  }

  const utilities = useUtilitiesContext()

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0]!
    html.setAttribute('lang', app.lang)

    if (Defaults.LANG !== app.lang) {
      Defaults.LANG = app.lang
    }
  }, [app.lang])

  return (
    <AppContext.Provider value={app}>
      <UtilitiesContext.Provider value={utilities}>
        <AlertUtilities manager={AlertManager} />
        <NotificationUtilities manager={NotificationManager} />
        {maintenanceMode ? <MaintenanceRouter /> : <Router />}
      </UtilitiesContext.Provider>
    </AppContext.Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

/**
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */

// serviceWorker.unregister();

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */

// report();
